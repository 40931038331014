import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { Entry, Category } from '../portal-response';

import { VideoPortalDataService } from '../video-portal-data.service';
import { SelectedNodeService } from '../selected-node.service';

@Component({
  selector: 'app-breadcrumb-navigation',
  templateUrl: './breadcrumb-navigation.component.html',
  styleUrls: ['./breadcrumb-navigation.component.css']
})
export class BreadcrumbNavigationComponent implements OnInit {

  // private mainCategoryIdSubscription: Subscription;
  // private subCategoryIdSubscription: Subscription;
  private routeParamsSubscription: Subscription;
  private getNodeNameSubscription: Subscription;

  subCategoryId: string;
  mainCategoryId: string;

  subCategoryName: string;
  mainCategoryName: string;

  categories: Category[];

  constructor(private route: ActivatedRoute,
    private videoPortalData: VideoPortalDataService,
    private selectedNodeService: SelectedNodeService) {
    //
    // this.mainCategoryIdSubscription = this.selectedNodeService.currentSelectedMainCategoryId.subscribe(categoryId => {
    //   this.mainCategoryId = categoryId;
    //   this.buildNavigation();
    // });
    //
    // this.subCategoryIdSubscription = this.selectedNodeService.currentSelectedSubCategoryId.subscribe(categoryId => {
    //   this.subCategoryId = categoryId;
    //   this.buildNavigation();
    // });
  }

  ngOnInit() {
    this.routeParamsSubscription = this.route.params.subscribe(params => {
      this.mainCategoryId = params['mainCategoryId'];
      this.subCategoryId = params['subCategoryId'];
      this.buildNavigation();
    })
  }

  buildNavigation() {
    this.getNodeNameSubscription = this.videoPortalData.getNodeName(this.subCategoryId).subscribe(name => this.subCategoryName = name);
    this.getNodeNameSubscription = this.videoPortalData.getNodeName(this.mainCategoryId).subscribe(name => this.mainCategoryName = name);
  }

  ngOnDestroy() {
    // this.subCategoryIdSubscription.unsubscribe();
    // this.mainCategoryIdSubscription.unsubscribe();
    this.routeParamsSubscription.unsubscribe();
    this.getNodeNameSubscription.unsubscribe();
  }
}
