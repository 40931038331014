import { Component } from '@angular/core';

import { VideoPortalDataService } from './video-portal-data.service';

import { PortalResponse, Entry } from './portal-response';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  entry: Entry;

  constructor(private videoPortalData: VideoPortalDataService) {
    videoPortalData.getNavigation();
    videoPortalData.getFeaturedEntries();
    videoPortalData.getLatestEntries();


    videoPortalData.currentSelectedEntry.subscribe(entry => {
      this.entry = entry;
    });
  }

  homeClicked() {
    this.entry = null;
  }
}
