import { Component, OnInit } from '@angular/core';

import { VideoPortalDataService } from '../video-portal-data.service';

import { Entry } from '../portal-response';

@Component({
  selector: 'app-video-browser-related',
  templateUrl: './video-browser-related.component.html',
  styleUrls: ['./video-browser-related.component.css']
})
export class VideoBrowserRelatedComponent implements OnInit {

  videos: Entry[];

  constructor(private videoPortalData: VideoPortalDataService) {
    videoPortalData.currentEntries.subscribe(data => {
      let tmpVideos = data;
      tmpVideos.forEach((item, index) => {
        item["streamUrl"] = "https://player.vimeo.com/video/" + item.streams[0].hls.substr(18, 9);
      });

      this.videos = tmpVideos;
      // var url = "https://player.vimeo.com/video/" + item.streams[0].hls.substr(18, 9);
    });
  }

  ngOnInit() {
  }

}
