import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { VideoPortalDataService } from '../video-portal-data.service';
import { SelectedNodeService } from '../selected-node.service';

import { Entry } from '../portal-response';

@Component({
  selector: 'app-video-browser',
  templateUrl: './video-browser.component.html',
  styleUrls: ['./video-browser.component.css']
})
export class VideoBrowserComponent implements OnInit {

  @Input() previewSize: string = "lg";
  @Input() title: string;
  @Input() showHeader: boolean = true;
  @Input() isSidebar: boolean = false;
  @ViewChild('browserContainer') private browserContainer: ElementRef;

  direction = '';
  disabled = false;

  videos: Entry[];
  mainCategoryId: string;
  subCategoryId: string;
  hasMore = false;
  isSearch: boolean;

  offset = 0;
  limit = 16;

  constructor(
    private route: ActivatedRoute,
    private selectedNodeService: SelectedNodeService,
    private videoPortalData: VideoPortalDataService) {
    videoPortalData.currentEntries.subscribe(data => {
      let tmpVideos = data;
      tmpVideos.forEach((item, index) => {
        item["streamUrl"] = "https://player.vimeo.com/video/" + item.streams[0].hls.substr(18, 9) + "?autoplay=1";
        if (item.thumbnail == undefined) {
          item.thumbnail = "./assets/mppl_thumb.jpg";
        }
      });

      this.videos = tmpVideos;
    });

    videoPortalData.currentHasMore.subscribe(hasMore => this.hasMore = hasMore);
  }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.scrollToTop();
        this.offset = 0;
        this.limit = 16;

        this.isSearch = params['searchString'] ? true : false;

        console.log(this.isSearch);

        if (this.isSearch) {
          var searchString = params['searchString'];
          this.title = "Search result for: " + searchString;
          this.videoPortalData.getEntriesByMetadata(searchString, "0", "16", false);
        }
        else {
          this.mainCategoryId = params['mainCategoryId'];
          this.subCategoryId = params['subCategoryId'];
          if (this.mainCategoryId != undefined) {
            this.selectedNodeService.setSelectedMainCategoryId(this.mainCategoryId);
          }
          if (this.subCategoryId != undefined) {
            this.selectedNodeService.setSelectedSubCategoryId(this.subCategoryId);
          }

          if (this.mainCategoryId == undefined && this.subCategoryId == undefined) {
            this.videoPortalData.getFeaturedEntries();
          } else {
            let id = this.subCategoryId || this.mainCategoryId;
            this.videoPortalData.getEntriesByNavigation(id, '' + this.offset, '' + this.limit, false);
            this.videoPortalData.getNodeName(id).subscribe(name => this.title = name);
            this.offset += this.limit;
          }
        }
      }
    );
  }

  loadMoreClicked(ev) {
    this.videoPortalData.getEntriesByNavigation(this.mainCategoryId, '' + this.offset, '' + this.limit, true);
    this.offset += this.limit;
  }

  scrollToTop() {
    try {
      this.browserContainer.nativeElement.scrollTop = 0;
    } catch (err) { }

  }
}
