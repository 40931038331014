export class PortalResponse {
  serverInfo: ServerInfo;
  hasMore: boolean;
  response: Entry[];
  categoryTree: Category[];
  playlist: Playlist;
}

export class ServerInfo {
  endPoint: string;
  time: string;
}

export class Entry {
  type: string;
  name: string;
  uvid: string;
  created: string;
  moddate: string;
  poster: string;
  thumbnail: string;
  categories: Category[];
  description: string;
  contentType: string;
  keywords: string[];
  streams: Stream[];
  isLiveEvent: boolean;
  featuredOnHomepage: FeaturedConfig;
  featuredOnPortal: FeaturedConfig;
  publish: string;
  unpublish: string;
  metadata: { [key: string]: string };
}

export class Category {
  id: string;
  name: string;
  parent_id: string;
}

export class Stream {
  hls: string;
}

export class FeaturedConfig {
  isFeatured: boolean;
  fromTime: string;
  untilTime: string;
}

export class Playlist {
  id: number;
  dirty: number
  allowFetch: number
  name: string
  version: string
  channelName: string
  items: PlaylistItem[]
  Tracks: Track[]
}

export class Track {
  mediaType: string
  items: PlaylistItem[]
  type: string
  name: string
}

export class PlaylistItem {
  Id_Playlist: number
  Id: number
  Id_MediaFile: number
  startTime: string
  fileSize: string
  materialId: string
  fileName: string
  script: string
  trimIn: string
  trimInLong: number
  trimOutLong: number
  trimOut: string
  realDuration: number
  category: string
  startType: string
  metadata: any[]
  secondaryEvents: any[]
  position: number
  exists: number
  eventtype: string
  playbackstate: string
  epg: boolean
  epgEventId: string
  guid: string
  downloadRequestURL: string
  parentPlaylistName: string
  downloadKind: any
  plannedDuration: number
  plannedStartTime: string
  description: string
  blockCode: string
  blend: boolean
  xFadeDuration: number
  audioFadeOutDuration: number
  audioFadeInDuration: number
  videoFadeOutDuration: number
  videoFadeInDuration: number
  IsControl: boolean
  IsLive: boolean
}
