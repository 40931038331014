import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { VideoPlayerComponent } from './video-player/video-player.component';
import { VideoBrowserComponent } from './video-browser/video-browser.component';
import { HomeComponent } from './home/home.component';
import { LivePlayerComponent } from './live-player/live-player.component';


const routes: Routes = [
  { path: '', redirectTo: '/live/en', pathMatch: 'full' },
  { path: 'featured', component: VideoBrowserComponent },
  { path: 'category', component: VideoBrowserComponent },
  { path: 'category/:mainCategoryId', component: VideoBrowserComponent },
  { path: 'category/:mainCategoryId/:subCategoryId', component: VideoBrowserComponent },
  { path: 'search/:searchString', component: VideoBrowserComponent },
  { path: 'video/:videoId', component: VideoPlayerComponent },
  { path: 'video/:mainCategoryId/:subCategoryId/:videoId', component: VideoPlayerComponent },
  { path: 'home', component: HomeComponent },
  // { path: 'live/:streamId/:scriptId', component: LivePlayerComponent }
  { path: 'live/:lang', component: LivePlayerComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
