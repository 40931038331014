import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  english = true;
  russian = false;

  constructor() { }

  ngOnInit() {
  }

  switchLanguage(language: string) {
    if (language == "english") {
      this.english = true;
      this.russian = !this.english;
    } else if (language == "russian") {
      this.english = false;
      this.russian = !this.english;
    }
  }
}
