import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';

import { VideoPortalDataService } from './video-portal-data.service';

import { SafePipe } from './safe-pipe';
import { VideoBrowserComponent } from './video-browser/video-browser.component';
import { VideoPreviewItemComponent } from './video-preview-item/video-preview-item.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { VideoBrowserRelatedComponent } from './video-browser-related/video-browser-related.component';
import { BreadcrumbNavigationComponent } from './breadcrumb-navigation/breadcrumb-navigation.component';
import { HomeComponent } from './home/home.component';
import { LivePlayerComponent } from './live-player/live-player.component';

import { VideoSectionViewComponent } from './video-section-view/video-section-view.component';
import { AppRoutingModule } from './/app-routing.module';

import { ScrollbarModule } from 'ngx-scrollbar';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LiveScheduleComponent } from './live-schedule/live-schedule.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    SafePipe,
    VideoBrowserComponent,
    VideoPreviewItemComponent,
    VideoPlayerComponent,
    VideoBrowserRelatedComponent,
    VideoSectionViewComponent,
    HomeComponent,
    LivePlayerComponent,
    BreadcrumbNavigationComponent,
    LiveScheduleComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ScrollbarModule,
    PerfectScrollbarModule,
    InfiniteScrollModule,
    NgbModule.forRoot()
  ],
  providers: [
    VideoPortalDataService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
