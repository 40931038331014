import { Component, OnInit } from '@angular/core';

import { VideoPortalDataService } from '../video-portal-data.service';

import { Playlist } from '../portal-response';
import { ScheduleItem } from '../schedule-item';

@Component({
  selector: 'app-live-schedule',
  templateUrl: './live-schedule.component.html',
  styleUrls: ['./live-schedule.component.css']
})
export class LiveScheduleComponent implements OnInit {

  playlist: Playlist;
  items = new Array<ScheduleItem>();


  constructor(private videoPortalDataService: VideoPortalDataService) { }

  ngOnInit() {
    this.videoPortalDataService.getEpgData().subscribe(data => {
      data.playlist.items.forEach((item, index) => {
        var dateNow = new Date(Date.now());
        var plannedStartTime = this.convertJsonDate(item.plannedStartTime);
        var valid = plannedStartTime > dateNow;
        if (this.items.length < 15 && valid) {
          let i1 = new ScheduleItem();
          i1.materialId = item.materialId;
          i1.startTime = this.convertJsonDate(item.plannedStartTime);

          var title = item.metadata.find(function(element) {
            return element.Key == "Title";
          })

          // i1.text = this.convertJsonDate(item.plannedStartTime).toString() + " " + name;
          if (title != undefined) {
            i1.text = title.Value || item.materialId;
          }
          else {
          i1.text = item.materialId
          }
          if (!item.materialId.toLowerCase().includes("promo") && !item.materialId.toLowerCase().includes("sponsor")) {
            this.items.push(i1);
          }
        }
      });
    });
  }

  convertJsonDate(value): Date {
    var a;
    if (typeof value === 'string') {
      a = /\/Date\((\d*)\)\//.exec(value);
      if (a) {
        return new Date(+a[1]);
      }
    }
    return value;
  }

}
