export class NavigationNode {
  id: string;
  parent_id: string;
  name: string;
  children: NavigationNode[];
  isExpanded: boolean;

  constructor() {
    this.isExpanded = false;
    this.children = new Array<NavigationNode>();
  }
}
