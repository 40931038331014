import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-live-player',
  templateUrl: './live-player.component.html',
  styleUrls: ['./live-player.component.css',
    '../../styles/shared/video-player.css']
})
export class LivePlayerComponent implements OnInit {

  livestreamURL = "https://livestream.com/accounts/8358876/events/8015038/player?width=640&height=360&enableInfoAndActivity=true&defaultDrawer=&autoPlay=true&mute=false";
  scriptId = "ls_embed_1533807424";

  language: string;
  languageShort: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.languageShort = params['lang'];
        this.loadStream(this.languageShort)
      }
    );
  }

  loadStream(lang: string) {
    let streamId = "8015038";
    let scriptId = "ls_embed_1533913075";

    switch (lang) {
      case "en":
        streamId = "8015038";
        scriptId = "ls_embed_1533913075";
        this.language = "english";
        break;
      case "ru":
        streamId = "8040422";
        scriptId = "ls_embed_1533913059";
        this.language = "russian";
        break;
    }

    let streamUrl = "https://livestream.com/accounts/8358876/events/" + streamId + "/player?width=640&height=360&enableInfoAndActivity=true&defaultDrawer=&autoPlay=true&mute=false";
    this.livestreamURL = streamUrl;
    this.scriptId = scriptId;
  }

  // switchLanguage(language: string) {
  //   if (language == "english") {
  //     this.livestreamURL = "https://livestream.com/accounts/8358876/events/8015038/player?width=640&height=360&enableInfoAndActivity=true&defaultDrawer=&autoPlay=true&mute=false";
  //     this.livestreamId = "ls_embed_1533807424";
  //   } else if (language == "russian") {
  //     this.livestreamURL = "https://livestream.com/accounts/8358876/events/8040422/player?width=640&height=360&enableInfoAndActivity=true&defaultDrawer=&autoPlay=true&mute=false";
  //     this.livestreamId = "ls_embed_1533911154";
  //   }
  // }
}
