import { Injectable, Inject } from '@angular/core';

import { BehaviorSubject, Observable, throwError } from 'rxjs';


import { HttpClient, HttpHeaders } from '@angular/common/http';

import { NavigationNode } from './navigation-node';
import { PortalResponse, Category, Entry } from './portal-response';

const localServer = "http://win-a6svo6c4bov";
const devServer = "http://ppdev.cubaplayout.com";
const publicServer = "http://vod.cubaplayout.com"
const server = devServer;

// const key = "PACE-DVL30";
const key = "DTD-FTP";

const getNodeNameUrl = server + '/CMS/VodService.svc/getNodeName?key=' + key + '&companyId=40&id=';
const getParentNodesUrl = server + '/CMS/VodService.svc/getNodeName?key=' + key + '&companyId=40&id=';
const getPortalDataUrl = server + '/CMS/VodService.svc/getPortalData?key=' + key + '&companyId=40&range%5bstart%5d=2017-05-12T09:24:42%2B00:00&range%5bend%5d=2019-12-31T09:24:42%2B00:00&links=true&https=true&categoryTree=true';
const getEntriesUrl = server + '/CMS/VodService.svc/getEntries?key=' + key + '&companyId=40&keyword={keyword}&metadata={metadata}&offset={offset}&limit={limit}';
const getNavigationUrl = server + '/CMS/VodService.svc/getNavigation?key=' + key + '&companyId=40';
const getEntriesByNavigationUrl = server + '/CMS/VodService.svc/categories/{navigationId}/entries?key=' + key + '&companyId=40&offset={offset}&limit={limit}';
const getEpgDataUrl = server + '/CMS/VodService.svc/getEpgData?key=' + key + '&companyId=40&serverName=WIN-86BJ5VM313A';

@Injectable()
export class VideoPortalDataService {

  private hasMore = new BehaviorSubject<boolean>(false);
  currentHasMore = this.hasMore.asObservable();

  private navigation = new BehaviorSubject<Category[]>(new Array<Category>());
  currentNavigation = this.navigation.asObservable();

  private selectedNavigationNode = new BehaviorSubject<NavigationNode>(new NavigationNode());
  currentSelectedNavigationNode = this.selectedNavigationNode.asObservable();

  private entries = new BehaviorSubject<Entry[]>(new Array<Entry>());
  currentEntries = this.entries.asObservable();

  private latestEntries = new BehaviorSubject<Entry[]>(new Array<Entry>());
  currentLatestEntries = this.latestEntries.asObservable();

  private selectedEntry = new BehaviorSubject<Entry>(null);
  currentSelectedEntry = this.selectedEntry.asObservable();

  constructor(private http: HttpClient) { }

  getCategoryTree(): Observable<PortalResponse[]> {
    return this.http.get<PortalResponse[]>(getPortalDataUrl);
  }

  getEntry(id: string): Observable<PortalResponse> {
    return this.http.get<PortalResponse>(server + '/CMS/VodService.svc/getEntry?key=' + key + '&companyId=40&id=' + id);
  }

  getFeaturedEntries() {
    this.http.get<PortalResponse>(server + '/CMS/VodService.svc/getFeaturedEntries?key=' + key + '&companyId=40').subscribe(data => {
      this.entries.next(data.response);
    });
  }

  getLatestEntries() {
    this.http.get<PortalResponse>(server + '/CMS/VodService.svc/getLatestEntries?key=' + key + '&companyId=40&limit=10').subscribe(data => {
      this.latestEntries.next(data.response);
    });
  }

  getEntries(keyword: string, metadata: string): Observable<PortalResponse> {
    return this.http.get<PortalResponse>(server + '/CMS/VodService.svc/getEntries?key=' + key + '&companyId=40&keyword=' + keyword + '&metadata=' + metadata)
  }

  getEntriesByNavigation(navigationId: string, offset: string, limit: string, append: boolean) {
    let url = getEntriesByNavigationUrl.replace("{navigationId}", navigationId).replace("{offset}", offset).replace("{limit}", limit);
    this.http.get<PortalResponse>(url).subscribe(result => {
      this.hasMore.next(result.hasMore);
      if (append) {
        this.entries.next(this.entries.getValue().concat(result.response));
      } else {
        this.entries.next(result.response);
      }
    });
  }

  getEntriesByMetadata(searchString: string, offset: string, limit: string, append: boolean) {
    let url = getEntriesUrl.replace("{keyword}", searchString).replace("{metadata}", "").replace("{offset}", offset).replace("{limit}", limit);
    this.http.get<PortalResponse>(url).subscribe(result => {
      this.hasMore.next(result.hasMore);
      if (append) {
        this.entries.next(this.entries.getValue().concat(result.response));
      } else {
        this.entries.next(result.response);
      }
    });
  }

  getEpgData() {
    return this.http.get<PortalResponse>(getEpgDataUrl);
  }

  // getNavigation(key: string, companyId: string) {
  getNavigation() {
    this.http.get<PortalResponse>(getNavigationUrl).subscribe(data => {
      this.navigation.next(data.categoryTree);
    })
  }

  changeSelectedEntry(entry: Entry) {
    this.selectedEntry.next(entry);
  }

  getNodeName(nodeId: string): Observable<string> {
    let url = getNodeNameUrl + nodeId;
    return this.http.get<string>(url);
  }

  getParentNodes(nodeId: string): Observable<string[]> {
    return this.http.get<string[]>(getParentNodesUrl + nodeId);
  }
}
