import { Component, OnInit, Input } from '@angular/core';

import { VideoPortalDataService } from '../video-portal-data.service';

import { Entry } from '../portal-response';

@Component({
  selector: 'app-video-section-view',
  templateUrl: './video-section-view.component.html',
  styleUrls: ['./video-section-view.component.css']
})
export class VideoSectionViewComponent implements OnInit {

  @Input() title: string;
  @Input() url: string;

  videos: Entry[];

  constructor(private videoPortalData: VideoPortalDataService) {
    videoPortalData.currentLatestEntries.subscribe(data => {
      let tmpVideos = data;
      tmpVideos.forEach((item, index) => {
        item["streamUrl"] = "https://player.vimeo.com/video/" + item.streams[0].hls.substr(18, 9);
      });

      this.videos = tmpVideos;
      // var url = "https://player.vimeo.com/video/" + item.streams[0].hls.substr(18, 9);
    });
  }

  ngOnInit() {
  }

}
