import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectedNodeService {

  constructor() { }

  private selectedMainCategoryId = new BehaviorSubject<string>("");
  currentSelectedMainCategoryId = this.selectedMainCategoryId.asObservable();

  private selectedSubCategoryId = new BehaviorSubject<string>("");
  currentSelectedSubCategoryId = this.selectedSubCategoryId.asObservable();

  setSelectedMainCategoryId(id: string) {
    this.selectedMainCategoryId.next(id);
  }

  setSelectedSubCategoryId(id: string) {
    this.selectedSubCategoryId.next(id);
  }
}
