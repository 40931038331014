import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { SelectedNodeService } from '../selected-node.service';

import { Entry } from '../portal-response';

@Component({
  selector: 'app-video-preview-item',
  templateUrl: './video-preview-item.component.html',
  styleUrls: ['./video-preview-item.component.css']
})
export class VideoPreviewItemComponent implements OnInit {

  private routeParamsSubscription: Subscription;

  @Input() videoEntry: Entry;
  @Input() size: string;

  subCategoryId: string;
  mainCategoryId: string;

  parentNodeId: string;
  nodeId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private selectedNodeService: SelectedNodeService) { }

  ngOnInit() {
    this.routeParamsSubscription = this.route.params.subscribe(
      params => {
        this.mainCategoryId = params['mainCategoryId'];
        this.subCategoryId = params['subCategoryId'];
      });
  }

  itemClicked() {
    // this.videoPortalData.changeSelectedEntry(this.videoEntry);

    if (this.subCategoryId == undefined) {
      var category = this.videoEntry.categories.find(category => {
        return category.parent_id == this.mainCategoryId;
      });
      this.subCategoryId = category.id;
    }

    this.router.navigate(['/video/' + this.mainCategoryId + '/' + this.subCategoryId + '/' + this.videoEntry.uvid]);
  }

  ngOnDestroy() {
    this.routeParamsSubscription.unsubscribe();
  }
}
