import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { VideoPortalDataService } from '../video-portal-data.service';
import { SelectedNodeService } from '../selected-node.service';

import { Category } from '../portal-response';
import { NavigationNode } from '../navigation-node';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  navigationNode: NavigationNode;
  selectedNode: NavigationNode;

  selectedMainCategoryId: string;
  selectedSubCategoryId: string;

  searchString: string;


  constructor(
    private videoPortalData: VideoPortalDataService,
    private selectedNodeService: SelectedNodeService,
    private router: Router) {
    this.videoPortalData.currentNavigation.subscribe(navigation => this.navigationNode = this.unflatten(navigation));
    this.videoPortalData.currentSelectedNavigationNode.subscribe(node => this.selectedNode = node);
    this.selectedNodeService.currentSelectedMainCategoryId.subscribe(node => this.selectedMainCategoryId = node)
    this.selectedNodeService.currentSelectedSubCategoryId.subscribe(node => this.selectedSubCategoryId = node);
  }

  ngOnInit() { }

  unflatten(arr: Category[]): NavigationNode {
    let rootNode: NavigationNode;
    rootNode = new NavigationNode()
    let mappedArr: NavigationNode[];
    mappedArr = new Array<NavigationNode>();

    for (var i = 0, len = arr.length; i < len; i++) {
      let arrElem = new NavigationNode();
      arrElem.id = arr[i].id;
      arrElem.parent_id = arr[i].parent_id;
      arrElem.name = arr[i].name;
      mappedArr[i] = arrElem;
    }

    mappedArr.forEach((item, index) => {
      if (item.parent_id != item.id) {
        var parent = mappedArr.find(function(element) {
          return element.id == item.parent_id;
        });
        parent.children.push(item);
      }
      else {
        rootNode = item;
      }
    })

    return rootNode;
  }

  selectNode(id) {
    this.selectedNodeService.setSelectedMainCategoryId(id);
    this.selectedNodeService.setSelectedSubCategoryId(undefined);
  }

  selectChildNode(id) {
    this.selectedNodeService.setSelectedSubCategoryId(id);
  }

  keydown(event) {
    if (event.keyCode == 13) {
      console.log(this.searchString);
      this.router.navigate(['/search/' + this.searchString]);
    }
  }

  navigate() {

  }
}
