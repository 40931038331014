import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from "rxjs";

import { Entry } from '../portal-response';
import { VideoPortalDataService } from '../video-portal-data.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnInit {

  private getEntrySubscription: Subscription;
  private routeParamsSubscription: Subscription;

  videoEntry: Entry;
  hideIFrame = true;

  nodeId: string;
  childNodeId: string;

  constructor(
    private route: ActivatedRoute,
    private videoPortalData: VideoPortalDataService,
    private location: Location) {
  }

  ngOnInit() {
    this.routeParamsSubscription = this.route.params.subscribe(
      params => {
        this.hideIFrame = true;
        const id = params['videoId'];
        this.getVideo(id);
      }
    );
  }

  getVideo(id: string) {
    // const id = this.route.snapshot.paramMap.get('id');

    this.getEntrySubscription = this.videoPortalData.getEntry(id).subscribe(data => {
      let video = data.response[0];
      video["streamUrl"] = "https://player.vimeo.com/video/" + video.streams[0].hls.substr(18, 9) + "?autoplay=1";
      this.videoEntry = video;
      this.hideIFrame = false;
    });
  }

  ngOnDestroy() {
    this.routeParamsSubscription.unsubscribe();
    this.getEntrySubscription.unsubscribe();
  }
}
